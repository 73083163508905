import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Collapse } from 'react-bootstrap-v5';
import { useHistory } from 'react-router-dom';
import CountUp from 'react-countup';
import { useFormik } from 'formik';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { NumericFormat } from 'react-number-format';
import { KTSVG, encryptId } from '../../../../_metronic/helpers';
import {
  useSelectComponentFormat,
  useSelectGenericPlus,
} from '../../catalogos/generico/hooks/genericoHook';
import {
  styleSelect,
  styleSelectPackages,
} from '../../../../_metronic/helpers/SelectStyles';
import { MenuComponent } from '../../../../_metronic/assets/ts/components/MenuComponent';
import {
  useCreateEditService,
  useCreateServiceCopy,
  useGetCatTaxes,
  useGetCatalogOfServicesCategories,
  useGetCatalogOfSubCategories,
  useGetDocumentsByServiceId,
  useGetNoteByService,
  useGetOne,
  useGetSuppliersByService,
  useRemoveService,
  useSelectServiceCatActives,
} from '../hooks/ServiceHook';
import { DocumentsList } from './sections/DocumentsList';
import { NoteList } from './sections/NoteList';
import { CreateNoteDetailModal } from './_modals/CreateNoteDetailModal';
import { CreateNoteServiceModal } from './_modals/CreateNoteModal';
import { SaleDataSection } from './sections/SaleDataSection';
import { CategoryModal } from './_modals/CategoryModal';
import { SubcategoryModal } from './_modals/SubcategoryModal';
import { SupplierList } from './sections/SupplierList';
import { PurchaseDataSection } from './sections/PurchaseDataSection';
import { ServiceTypeModal } from './_modals/ServiceTypeModal';
import { BussinesUnitModal } from './_modals/BussinesUnitModal';
import { useUpdateSupplier } from '../../products/hooks/ProductsHook';
import { calcularPrecioImpuestos } from '../../../../_metronic/helpers/PriceCalculations';

type Props = {
  id: number;
  onRefreshHistory: () => void;
};
const productSchema = Yup.object().shape({
  nombre: Yup.string().required('Nombre del servicio es requerido.'),
  unidad_medida_venta_id: Yup.number()
    .min(1, 'Unidad de medida es requerido')
    .required('Unidad de medida es requerido'),
});

const ServiceForm: React.FC<Props> = ({ id, onRefreshHistory }) => {
  const history = useHistory();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { initValues, loadingRequest } = useGetOne(Number(id));
  const { removeServices, isDeletedCorrect } = useRemoveService();
  const sectionTabs = useRef<HTMLDivElement>(null);
  const [tabs, setTabs] = useState<string | 'NOTE' | 'DOC' | 'PROVEE'>('NOTE');
  const [tabsDetail, setTabsDetail] = useState<string | 'PRICE'>('PRICE');
  const [IsEdit, setIsEdit] = useState<boolean>(false);
  const [dataSupplierSelected, setDataSupplierSelected] = useState<any>();
  const [suppInit, setSuppInit] = useState<any>();
  const [proveedoresForm, setProveedoresForm] = useState<any>([]);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [calculatePrice, setCalculatePrice] = useState<number>(0);
  const [codeServiceType, setCodeServiceType] = useState<string>('');
  const [selectCategoryAdding, setSelectCategoryAdding] =
    useState<boolean>(false);
  const [selectSubcategoryAdding, setSelectSubcategoryAdding] =
    useState<boolean>(false);
  const [selectServiceTypeAdding, setSelectServiceTypeAdding] =
    useState<boolean>(false);
  const [selectBussinesUnitAdding, setSelectBussinesUnitAdding] =
    useState<boolean>(false);

  const [totalPriceProduct, setTotalPriceProduct] = useState<number>(0);
  const [totalPriceProductToPurchase, setTotalPriceProductToPurchase] =
    useState<number>(0);
  const [newService, setNewService] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [openSection, setOpenSection] = useState<boolean>(false);
  const [openSectionPurchase, setOpenSectionPurchase] =
    useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>(
    process.env.PUBLIC_URL + '/media/avatars/photo.png'
  );
  const [categoryId, setCategoryId] = useState<number>(0);
  const [subCategoryId, setSubCategoryId] = useState<number>(0);
  const [successPreviewUploadImg, setSuccessPreviewUploadImg] =
    useState<boolean>(false);
  const [isOpenCategoryModal, setIsOpenCategoryModal] =
    useState<boolean>(false);
  const [isOpenSubcategoryModal, setIsOpenSubcategoryModal] =
    useState<boolean>(false);
  const [isOpenServiceTypeModal, setIsOpenServiceTypeModal] =
    useState<boolean>(false);

  const [isOpenBussinesUnitModal, setIsOpenBussinesUnitModal] =
    useState<boolean>(false);

  const [codeMeasure, setCodeMeasure] = useState<string>('');
  const [codeMeasurePurchase, setCodeMeasurePurchase] = useState<string>();
  const [codeCurrency, setCodeCurrency] = useState<string>('');
  const [codeCurrencyPurchase, setCodeCurrencyPurchase] = useState<string>();
  const [taxPercentaje, setTaxPercentaje] = useState<number>(0);
  const [profit, setProfit] = useState<number>(0);
  const [supplierTypePurchase, setSupplierTypePurchase] =
    useState<boolean>(false);
  const [priceSale, setPriceSale] = useState<number>(0);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [selectedOptionCurrency, setSelectedOptionCurrency] = useState<
    any | null
  >(null);
  const [selectedOptionTypeTaxe, setSelectedOptionTypeTaxe] = useState<
    any | null
  >(null);
  const [selectedOptionCategory, setSelectedOptionCategory] = useState<
    any | null
  >(null);
  const [selectedOptionServiceType, setSelectedOptionServiceType] = useState<
    any | null
  >(null);

  const [selectedOptionSubcategory, setSelectedOptionSubcategory] = useState<
    any | null
  >(null);

  const [selectedOptionBussinesUnit, setSelectedOptionBussinesUnit] = useState<
    any | null
  >(null);

  // DOCUMENTS
  const [reloadDocuments, setReloadDocuments] = useState(Math.random() * 40);
  const [reloadSuppliers, setReloadSuppliers] = useState(Math.random() * 40);
  const { dataDocuments, loadingDocuments } = useGetDocumentsByServiceId(
    id,
    reloadDocuments
  );

  // NOTAS
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
  const [showNoteDetailModal, setShowNoteDetailModal] =
    useState<boolean>(false);
  const [reloadGridNotes, setReloadGridNotes] = useState<number>(
    Math.random() * 40
  );
  const { notes, loadingNote } = useGetNoteByService(id, reloadGridNotes);

  // SUPPIERS
  const [reloadGridSupplier, setReloadGridSupplier] = useState<number>(
    Math.random() * 40
  );
  const { suppliers } = useGetSuppliersByService(
    id,
    reloadGridSupplier,
    loadingRequest
  );

  const {
    createServiceCopy,
    loadingService: loadingCopy,
    errorRequest: errorRequestCopy,
  } = useCreateServiceCopy();
  const { createEditService, loadingService, errorRequest, serviceId } =
    useCreateEditService(IsEdit);

  const [reloadCategoryList, setReloadCategoryList] = useState<number>(
    Math.random() * 40
  );
  const [reloadSubcategoryList, setReloadSubcategoryList] = useState<number>(
    Math.random() * 40
  );

  const [reloadServiceList, setReloadServiceList] = useState<number>(
    Math.random() * 40
  );

  const [reloadBussinesUnit, setReloadBussinesUnit] = useState<number>(
    Math.random() * 40
  );
  const {
    updateSupplier,
    errorRequest: errUpd,
    reloadInfo,
  } = useUpdateSupplier();

  // CATALOGO DE TIPO DE SERVICIO
  const { catalog: ServiceType, loadingRequest: loadingPT } =
    useSelectGenericPlus('TSERV', reloadServiceList);
  const { catalog: unitBussinesUnit, loadingRequest: loadingUM } =
    useSelectGenericPlus('UNEG', reloadBussinesUnit);

  // CAMBIOS
  const [codeInter, setCodeInter] = useState<string>('');
  const [selectedOptionUnitMeasure, setSelectedOptionUnitMeasure] = useState<
    any | null
  >(null);
  const { optionsSelect: currencyUnit } = useSelectComponentFormat(
    'UMON',
    true
  );
  const { optionsSelect: UnitMeasure } = useSelectComponentFormat(
    'UNDMEDS',
    true
  );

  const { catalog: profile, loadingRequest: loadingPro } =
    useSelectGenericPlus('PASOC');
  const { catalog: actives, loadingRequest: loadingActives } =
    useSelectServiceCatActives();
  const { optionsSelectTaxes: TaxeType, loadingGetTaxes: loadingTT } =
    useGetCatTaxes();
  const { loadingCatServices, selectData: categories } =
    useGetCatalogOfServicesCategories(reloadCategoryList);
  const { loadingSubCatProducts, selectData: subcategories } =
    useGetCatalogOfSubCategories(categoryId, reloadSubcategoryList);
  const [price, setPrice] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const generateCopy = async (id: number) => {
    await createServiceCopy(id);
  };

  const currentUnitMeasureSaleProduct = (measure: any) => {
    if (measure) {
      const {
        data: { code, id },
      } = measure;

      setCodeMeasure(code);
      servicesForm.setFieldValue('unidad_medida_venta_id', id);
    } else {
      setCodeMeasure('');
      servicesForm.setFieldValue('unidad_medida_venta_id', 0);
    }
  };
  const currentUnitMeasurePurchaseProduct = (measure: any) => {
    if (measure) {
      const {
        data: { code, id },
      } = measure;

      setCodeMeasurePurchase(code);
      servicesForm.setFieldValue('unidad_medida_compra_id', id);
    } else {
      setCodeMeasurePurchase('');
      servicesForm.setFieldValue('unidad_medida_compra_id', 0);
    }
  };
  const currentUnitCurrencyProduct = (currency: any) => {
    if (currency) {
      const {
        data: { code, id },
      } = currency;

      setCodeCurrency(code);
      servicesForm.setFieldValue('unidad_moneda_venta_id', id);
    } else {
      setCodeCurrency('');

      servicesForm.setFieldValue('unidad_moneda_venta_id', null);
    }
  };
  const currentTypePriceToSale = (type: number) => {
    servicesForm.setFieldValue('tipo_precio_venta_id', type);
  };

  const currentUnitCurrencyPurchaseProduct = (currency: any) => {
    if (currency) {
      const {
        data: { code, id },
      } = currency;

      setCodeCurrencyPurchase(code);
      servicesForm.setFieldValue('unidad_moneda_compra_id', id);
    } else {
      setCodeCurrencyPurchase('');

      servicesForm.setFieldValue('unidad_moneda_compra_id', null);
    }
  };
  const currentProfit = (profit: number) => {
    setProfit(profit);
  };
  const isSupplierType = (isSupp: boolean) => {
    setSupplierTypePurchase(isSupp);
    if (isSupp) {
      setTabs('PROVEE');
    } else {
      setDataSupplierSelected(null);
    }
  };
  const updatePriceProduct = (
    total: number,
    priceWithoutTaxes: number,
    priceType: number
  ) => {
    const fixedPrice = 1;
    setTotalPriceProduct(total);
    if (priceType && priceType === fixedPrice) {
      servicesForm.setFieldValue('precio_venta', total);
      servicesForm.setFieldValue('precio_fijo_venta', priceWithoutTaxes);
      setPriceSale(priceWithoutTaxes);
    } else {
      servicesForm.setFieldValue('precio_venta', 0);
      servicesForm.setFieldValue('precio_fijo_venta', 0);
      setPriceSale(0);
    }
  };
  const updatePricePurchaseProduct = (
    total: number,
    priceWithoutTaxes: number
  ) => {
    setTotalPriceProductToPurchase(total);

    if (supplierTypePurchase) {
      servicesForm.setFieldValue('precio_proveedor_compra', priceWithoutTaxes);
    } else {
      servicesForm.setFieldValue('precio_proveedor_compra', 0);
    }
    servicesForm.setFieldValue('precio_compra', total);
    servicesForm.setFieldValue('precio_fijo_compra', priceWithoutTaxes);
  };

  const openModal = (isOpen: boolean) => {
    setShowNoteModal(isOpen);
  };
  const openDetailModal = (isOpen: boolean) => {
    setShowNoteDetailModal(isOpen);
  };
  const supplierData = (data: any) => {
    const { id } = data;
    servicesForm.setFieldValue('proveedor_id', id);
    setDataSupplierSelected(data);
  };

  const servicesForm = useFormik({
    initialValues: initValues,
    validationSchema: productSchema,
    enableReinitialize: true,
    onSubmit: (values: any, { setSubmitting }) => {
      setLoading(true);
      values.proveedores = proveedoresForm;
      createEditService(values);
    },
  });

  const changeCategory = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionCategory(selectedOption);

      servicesForm.setFieldValue('sub_categoria_id', 0);
      setSelectedOptionSubcategory(null);
      setSelectSubcategoryAdding(false);
      if (value <= 1) {
        if (Number(value) === 0) {
          setIsOpenCategoryModal(!isOpenCategoryModal);
        }
      }
      servicesForm.setFieldValue('categoria_id', value);
      setCategoryId(value);
    } else {
      setCategoryId(0);
      servicesForm.setFieldValue('categoria_id', 0);
      servicesForm.setFieldValue('sub_categoria_id', 0);
      setSelectedOptionSubcategory(null);
    }
  };
  const changeSubcategory = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionSubcategory(selectedOption);
      servicesForm.setFieldValue('sub_categoria_id', value);
      if (Number(value) === 0) {
        setIsOpenSubcategoryModal(!isOpenSubcategoryModal);
      }
    } else {
      servicesForm.setFieldValue('sub_categoria_id', 0);
      setSelectedOptionSubcategory(null);
    }
  };
  const changeBussinesUnit = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionBussinesUnit(selectedOption);
      setSelectBussinesUnitAdding(false);

      if (value <= 1) {
        if (Number(value) === 0) {
          setIsOpenBussinesUnitModal(!isOpenBussinesUnitModal);
        }
      }

      servicesForm.setFieldValue('unidad_negocio_id', value);
    } else {
      servicesForm.setFieldValue('unidad_negocio_id', 0);
    }
  };
  const changeServiceType = (selectedOption: any) => {
    if (selectedOption) {
      const {
        value,
        data: { code },
      } = selectedOption;
      setCodeServiceType(code);
      setSelectedOptionServiceType(selectedOption);
      setSelectServiceTypeAdding(false);

      if (value <= 1) {
        if (Number(value) === 0) {
          setIsOpenServiceTypeModal(!isOpenServiceTypeModal);
        }
      }

      servicesForm.setFieldValue('tipo_id', value);
    } else {
      servicesForm.setFieldValue('tipo_id', 0);
      setCodeServiceType('');
    }
  };
  const changeTypeTaxe = (selectedOption: any) => {
    if (selectedOption) {
      const {
        value,
        data: { porcentaje },
      } = selectedOption;
      setTaxPercentaje(Number(porcentaje));
      setSelectedOptionTypeTaxe(selectedOption);
      servicesForm.setFieldValue('impuesto_id', value);
    } else {
      servicesForm.setFieldValue('impuesto_id', 0);
      setTaxPercentaje(0);
    }
  };
  const changeProfile = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;

      servicesForm.setFieldValue('perfil_id', value);
      servicesForm.setFieldValue('activo_id', null);
    } else {
      servicesForm.setFieldValue('perfil_id', null);
    }
  };
  const changeActive = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;

      servicesForm.setFieldValue('activo_id', value);
      servicesForm.setFieldValue('perfil_id', null);
    } else {
      servicesForm.setFieldValue('activo_id', null);
    }
  };

  const updateSuppliers = () => {
    setReloadGridSupplier(Math.random() * 40);
  };
  const getCalculatePrice = (price: number) => {
    setCalculatePrice(price);
  };
  const applyTax = (apply: boolean) => {
    servicesForm.setFieldValue('aplica_impuesto_precio_venta', apply);
  };
  const IsCheckedSupplierSecction = (isChecked: boolean) => {
    setIsChecked(isChecked);
  };

  // CAMBIOS
  const changeCurrencyUnit = (selectedOption: any) => {
    if (selectedOption) {
      const {
        value,
        data: { code },
      } = selectedOption;
      setSelectedOptionCurrency(selectedOption);
      servicesForm.setFieldValue('unidad_moneda_venta_id', value);
      setCodeInter(code);
    } else {
      servicesForm.setFieldValue('unidad_moneda_venta_id', 0);
      setCodeInter('');
    }
  };
  const changeUnitMeasure = (selectOption: any) => {
    if (selectOption) {
      const {
        data: { code, id },
      } = selectOption;
      setCodeMeasure(code);
      setSelectedOptionUnitMeasure(selectOption);
      servicesForm.setFieldValue('unidad_medida_venta_id', id);
    } else {
      setCodeMeasure('');
      setSelectedOptionUnitMeasure(selectOption);
      servicesForm.setFieldValue('unidad_medida_venta_id', 0);
    }
  };

  useEffect(() => {
    const { id } = initValues;
    if (id === 0) {
      let currencyUnitDefault: any = currencyUnit.filter(
        (item) => item.data.code === 'MXN'
      );
      if (currencyUnitDefault.length) {
        servicesForm.setFieldValue(
          'unidad_moneda_venta_id',
          currencyUnitDefault[0].value
        );
        setCodeInter(currencyUnitDefault[0].code);
      }

      setSelectedOptionCurrency(currencyUnitDefault);
    }
  }, [initValues, currencyUnit]);

  useEffect(() => {
    setNewService(serviceId);
  }, [serviceId]);

  useEffect(() => {
    if (loadingService) {
      if (errorRequest) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
          if (!IsEdit) {
            history.push(
              `/cotizaciones/servicios/edit/${encryptId(newService.toString())}`
            );
          } else {
            setTimeout(() => {
              history.push('/cotizaciones/servicios');
            }, 1000);
          }
        }, 1000);
      }
    }
  }, [loadingService, newService]);

  useEffect(() => {
    if (isDeletedCorrect) {
      setTimeout(() => {
        history.push('/cotizaciones/servicios');
      }, 1000);
    }
  }, [isDeletedCorrect]);

  useEffect(() => {
    if (id > 0) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [id]);

  useEffect(() => {
    const { id, imagen, categoria_id, sub_categoria_id, impuesto_porcentaje } =
      initValues;
    if (id > 0) {
      setPreviewImage(imagen);
      setSuccessPreviewUploadImg(true);
      setTaxPercentaje(impuesto_porcentaje);

      if (categoria_id) {
        const category = categories.filter((item) => item.id === categoria_id);

        if (category.length) {
          setSelectedOptionCategory(category[0]);
        }

        setCategoryId(categoria_id);
        if (sub_categoria_id) {
          setSubCategoryId(sub_categoria_id);
        }
      }
    }
  }, [initValues, categories]);

  useEffect(() => {
    if (subCategoryId) {
      const currentSubCategory = subcategories.filter(
        (item: any) => item.value === subCategoryId
      );

      setSelectedOptionSubcategory(currentSubCategory);
    }
  }, [subCategoryId, subcategories]);

  useEffect(() => {
    onRefreshHistory();
  }, [reloadDocuments, reloadGridNotes]);

  useEffect(() => {
    if (selectCategoryAdding) {
      const lastPositionCategory = categories[categories.length - 1];
      setSelectedOptionCategory(lastPositionCategory);
      setCategoryId(lastPositionCategory.id);
      servicesForm.setFieldValue('categoria_id', lastPositionCategory.id);
    }
  }, [categories]);

  useEffect(() => {
    if (selectSubcategoryAdding) {
      const lastPositionSubcategory = subcategories[subcategories.length - 1];
      setSelectedOptionSubcategory(lastPositionSubcategory);
      servicesForm.setFieldValue(
        'sub_categoria_id',
        lastPositionSubcategory.value
      );
    }
  }, [subcategories]);

  useEffect(() => {
    if (selectServiceTypeAdding) {
      const lastPositionServiceType = ServiceType[1];
      setSelectedOptionServiceType(lastPositionServiceType);
      servicesForm.setFieldValue('tipo_id', lastPositionServiceType.id);
    }
  }, [ServiceType]);

  useEffect(() => {
    if (selectBussinesUnitAdding) {
      const lastPositionBussinesUnit = unitBussinesUnit[1];
      setSelectedOptionBussinesUnit(lastPositionBussinesUnit);
      servicesForm.setFieldValue(
        'unidad_negocio_id',
        lastPositionBussinesUnit.id
      );
    }
  }, [unitBussinesUnit]);

  useEffect(() => {
    if (id > 0 && initValues.id > 0) {
      const {
        precio_venta,
        unidadMedidaVenta,
        unidadMonedaVenta,
        unidadMedidaCompra,
        unidadMonedaCompra,
        tipoPrecioVenta,
        tipo,
        impuesto,
      } = initValues;
      servicesForm.setFieldValue('precio_venta', precio_venta);
      servicesForm.setFieldValue('tipo_precio_venta_id', tipoPrecioVenta?.id);
      if (unidadMedidaVenta) {
        const { id, code } = unidadMedidaVenta;
        setCodeMeasure(code);
        servicesForm.setFieldValue('unidad_medida_venta_id', id);
      }
      if (unidadMonedaVenta) {
        const { id, code } = unidadMonedaVenta;
        setCodeCurrency(code);
        servicesForm.setFieldValue('unidad_moneda_venta_id', id);
      }
      if (unidadMedidaCompra) {
        const { code } = unidadMedidaCompra;
        setCodeMeasurePurchase(code);
      }
      if (unidadMonedaCompra) {
        const { code } = unidadMonedaCompra;
        setCodeCurrencyPurchase(code);
      }

      if (tipo) {
        const { code } = tipo;
        setCodeServiceType(code);
      }

      if (TaxeType.length) {
        if (impuesto) {
          const { siglas, porcentaje } = impuesto;
          setTaxPercentaje(Number(porcentaje));
          const tax = TaxeType.filter((item) => item.data?.siglas === siglas);
          setSelectedOptionTypeTaxe(tax[0]);
          servicesForm.setFieldValue('impuesto_id', tax[0].value);
        }
      }
    } else {
      if (TaxeType.length) {
        const taxDefault = TaxeType.filter(
          (item) => item.data?.siglas === 'IVA'
        );
        setSelectedOptionTypeTaxe(taxDefault);
        const {
          data: { porcentaje },
        } = taxDefault[0];
        setTaxPercentaje(Number(porcentaje));

        servicesForm.setFieldValue('impuesto_id', taxDefault[0].value);
      }
    }
  }, [initValues, id, TaxeType]);

  useEffect(() => {
    if (suppliers.length && id && isFirst) {
      const getSupplierSelected = suppliers.filter((item) => item.seleccionado);

      if (getSupplierSelected.length) {
        setSuppInit(getSupplierSelected[0]);
        supplierData(getSupplierSelected[0]);
      }
    }
  }, [suppliers]);

  useEffect(() => {
    if (suppInit && dataSupplierSelected && supplierTypePurchase) {
      setIsFirst(false);
      const { id: supplierSelectedId } = suppInit;
      const { id: supplierNewSelectedId } = dataSupplierSelected;

      if (supplierSelectedId != supplierNewSelectedId) {
        setShowAlert(true);
      } else if (supplierSelectedId === supplierSelectedId) {
        setShowAlert(false);
      }
    }
  }, [suppInit, dataSupplierSelected]);

  useEffect(() => {
    if (!id) {
      let precio = calcularPrecioImpuestos(
        Number(price),
        Number(taxPercentaje)
      );
      servicesForm.setFieldValue('precio_venta', precio);
      setTotalPrice(precio);
    }
  }, [id, price, taxPercentaje]);

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <>
      <form
        id="kt_account_form"
        className="form"
        action="#"
        noValidate
        onSubmit={servicesForm.handleSubmit}
      >
        <div className="card mb-6 mb-xl-9">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                  <div
                    className="image-input image-input-outline me-10"
                    data-kt-image-input="true"
                  >
                    <label htmlFor="imageUpload">
                      <img
                        src={previewImage ? previewImage : ''}
                        className={`image-input-wrapper w-175px h-175px cursor-pointer `}
                        crossOrigin="anonymous"
                        alt="Producto"
                      />
                    </label>

                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title="Cambiar imagen"
                    >
                      <i className="bi bi-pencil-fill fs-7"></i>
                      <input
                        type="file"
                        id="imageUpload"
                        name="img"
                        accept=".png, .jpg, .jpeg"
                        onChange={(event) => {
                          if (event.currentTarget.files) {
                            let file = event.currentTarget.files[0];
                            let reader = new FileReader();
                            reader.onload = () => {
                              const csv: string = reader.result as string;
                              setPreviewImage(csv);
                              setSuccessPreviewUploadImg(true);
                            };
                            reader.readAsDataURL(file);
                            servicesForm.setFieldValue('image', file);
                          }
                        }}
                      />
                    </label>
                  </div>
                  <div className="flex-grow-1">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-7 p-0">
                        <div className="w-100">
                          <input
                            className="form-control mb-2 w-100 border-0"
                            style={{
                              width: '100%',
                              padding: '15px',
                              fontSize: '24px',
                              boxSizing: 'border-box',
                              marginBottom: '20px',
                            }}
                            data-kt-element="input"
                            placeholder="Nombre *"
                            maxLength={40}
                            {...servicesForm.getFieldProps('nombre')}
                          ></input>
                          {servicesForm.touched.nombre &&
                            servicesForm.errors.nombre && (
                              <div className="fv-plugins-message-container text-danger  w-100 mb-3">
                                <span role="alert">
                                  {servicesForm.errors.nombre}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                      {id > 0 ? (
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5 p-0">
                          <div className="elements-end ">
                            <div className="mr-05">
                              <div
                                data-kt-menu-trigger="hover"
                                data-kt-menu-attach="parent"
                                data-kt-menu-placement="bottom-end"
                                data-kt-menu-flip="bottom"
                                data-kt-menu="true"
                                className="btn btn-sm btn-icon btn-light-primary center-items"
                                title="Opciones"
                              >
                                <KTSVG
                                  path="/media/icons/duotune/general/gen052.svg"
                                  className="svg-icon-1"
                                />
                              </div>
                              <div
                                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                                data-kt-menu="true"
                              >
                                <div className="menu-item px-3 center-items">
                                  <a
                                    className="menu-link px-3 fs-8 "
                                    href="#/"
                                    onClick={async () => {
                                      await generateCopy(id);
                                    }}
                                  >
                                    <KTSVG
                                      path="/media/icons/duotune/general/gen054.svg"
                                      className="svg-icon-6 mr-05"
                                    />
                                    <span>Hacer una copia</span>
                                  </a>
                                </div>
                                {/* <div className="menu-item px-3 center-items">
                                  <a
                                    className="menu-link px-3 fs-8"
                                    href="#/"
                                    onClick={() => {
                                      Swal.fire({
                                        title: '¿Estas seguro?',
                                        html: `
                                        <div className="text-gray-600">
                                          Se va a eliminar el servicio con nombre <b>${initValues.nombre}</b>, una vez
                                          eliminado ya no se podrá modificar ningún dato de este
                                          servicio.
                                        </div>
                                        `,
                                        showCancelButton: true,
                                        padding: '2em',
                                        cancelButtonText: 'Cancelar',
                                        confirmButtonText: 'Aceptar',
                                        reverseButtons: true,
                                        customClass: {
                                          confirmButton: 'btn btn-primary',
                                          cancelButton: 'btn btn-light me-3',
                                        },
                                      }).then(async (result) => {
                                        if (result.isConfirmed) {
                                          await removeServices(Number(id));
                                        }
                                      });
                                    }}
                                  >
                                    <KTSVG
                                      path="/media/icons/duotune/general/gen027.svg"
                                      className="svg-icon-6 mr-05 "
                                    />
                                    Eliminar
                                  </a>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-7 p-0">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="form-label w-auto">
                              Descripción
                            </label>
                          </div>
                          <textarea
                            className="form-control w-100"
                            data-kt-element="input"
                            rows={2}
                            style={{ resize: 'none' }}
                            placeholder="Agregue una descripción"
                            maxLength={255}
                            {...servicesForm.getFieldProps('descripcion')}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5 p-0">
                        <div className="d-flex justify-content-center ms-8 me-10">
                          <div
                            className="w-100 d-flex "

                            // style={{ height: '66px' }}
                          >
                            <div className="text-center w-100">
                              <div className="w-100">
                                <label className="form-label w-auto text-center">
                                  SKU
                                </label>
                              </div>
                              <input
                                type="text"
                                className="fs-2x fw-bold form-control"
                                style={{ color: '#1B84FF' }}
                                maxLength={30}
                                {...servicesForm.getFieldProps('sku')}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto large-text">
                      Unidad de negocio
                    </label>
                  </div>
                  <Select
                    value={
                      servicesForm.values.unidad_negocio_id
                        ? unitBussinesUnit.find(
                            (option) =>
                              option.value ===
                              servicesForm.values.unidad_negocio_id
                          )
                        : null
                    }
                    options={unitBussinesUnit}
                    styles={styleSelectPackages}
                    placeholder={'Seleccione'}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{'No se encontraron registros'}</span>;
                    }}
                    onChange={changeBussinesUnit}
                    isLoading={loadingUM}
                    isClearable
                    backspaceRemovesValue
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Categoría</label>
                  </div>
                  <Select
                    options={categories}
                    value={
                      servicesForm.values.categoria_id
                        ? categories.find(
                            (option) =>
                              option.value === servicesForm.values.categoria_id
                          )
                        : null
                    }
                    styles={styleSelectPackages}
                    placeholder={'Seleccione'}
                    isSearchable
                    isClearable
                    noOptionsMessage={() => {
                      return <span>{'No se encontraron registros'}</span>;
                    }}
                    onChange={changeCategory}
                    isLoading={loadingCatServices}
                    backspaceRemovesValue
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Subcategoría</label>
                  </div>
                  <Select
                    isDisabled={servicesForm.values.categoria_id <= 0}
                    options={subcategories}
                    styles={styleSelectPackages}
                    placeholder={'Seleccione'}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{'No se encontraron registros'}</span>;
                    }}
                    onChange={changeSubcategory}
                    value={selectedOptionSubcategory}
                    isLoading={loadingSubCatProducts}
                    backspaceRemovesValue
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">
                      Tipo de servicio
                    </label>
                  </div>
                  <Select
                    value={
                      servicesForm.values.tipo_id
                        ? ServiceType.find(
                            (option) =>
                              option.value === servicesForm.values.tipo_id
                          )
                        : null
                    }
                    options={ServiceType}
                    styles={styleSelectPackages}
                    placeholder={'Seleccione'}
                    isSearchable={true}
                    noOptionsMessage={() => {
                      return <span>{'No se encontraron registros'}</span>;
                    }}
                    onChange={changeServiceType}
                    isLoading={loadingPT}
                    isClearable
                    backspaceRemovesValue
                  />
                </div>
              </div>
              {codeServiceType === 'TSMO' ? (
                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="form-label w-auto">
                        Perfil asociado
                      </label>
                    </div>
                    <Select
                      value={
                        servicesForm.values.perfil_id
                          ? profile.find(
                              (option) =>
                                option.value === servicesForm.values.perfil_id
                            )
                          : null
                      }
                      options={profile}
                      styles={styleSelect}
                      placeholder={'Seleccione'}
                      isSearchable={true}
                      noOptionsMessage={() => {
                        return <span>{'No se encontraron registros'}</span>;
                      }}
                      onChange={changeProfile}
                      isLoading={loadingPro}
                      isClearable
                      backspaceRemovesValue
                    />
                  </div>
                </div>
              ) : codeServiceType === 'TSACT' ? (
                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="form-label w-auto">
                        Activo asociado
                      </label>
                    </div>
                    <Select
                      value={
                        servicesForm.values.activo_id
                          ? actives.find(
                              (option) =>
                                option.value === servicesForm.values.activo_id
                            )
                          : null
                      }
                      options={actives}
                      styles={styleSelect}
                      placeholder={'Seleccione'}
                      isSearchable={true}
                      noOptionsMessage={() => {
                        return <span>{'No se encontraron registros'}</span>;
                      }}
                      onChange={changeActive}
                      isLoading={loadingActives}
                      isClearable
                      backspaceRemovesValue
                    />
                  </div>
                </div>
              ) : null}
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                {id > 0 ? (
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="form-label w-auto">
                        Tipo de impuesto
                      </label>
                    </div>
                    <Select
                      options={TaxeType}
                      value={
                        servicesForm.values.impuesto_id
                          ? TaxeType.find(
                              (option) =>
                                option.value === servicesForm.values.impuesto_id
                            )
                          : null
                      }
                      styles={styleSelect}
                      placeholder={'Seleccione'}
                      isSearchable={true}
                      noOptionsMessage={() => {
                        return <span>{'No se encontraron registros'}</span>;
                      }}
                      onChange={changeTypeTaxe}
                      isLoading={loadingTT}
                      isClearable
                      backspaceRemovesValue
                    />
                  </div>
                ) : (
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className="form-label w-auto">
                        Unidad de moneda
                      </label>
                    </div>
                    <Select
                      options={currencyUnit}
                      value={selectedOptionCurrency}
                      styles={styleSelect}
                      placeholder={'Seleccione'}
                      isSearchable={true}
                      noOptionsMessage={() => {
                        return <span>{'No se encontraron registros'}</span>;
                      }}
                      onChange={changeCurrencyUnit}
                      // isLoading={loadingTT}
                      // isClearable={id > 0 ? true : false}
                      backspaceRemovesValue
                    />
                  </div>
                )}
              </div>
              {id === 0 && (
                <>
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4 mt-5">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className=" form-label w-auto required">
                          Unidad de medida
                        </label>
                      </div>
                      <div className="d-flex">
                        <Select
                          options={UnitMeasure}
                          styles={styleSelect}
                          className="w-100"
                          placeholder={'Seleccione'}
                          isSearchable={true}
                          noOptionsMessage={() => {
                            return <span>{'No se encontraron registros'}</span>;
                          }}
                          onChange={changeUnitMeasure}
                          value={selectedOptionUnitMeasure}
                          backspaceRemovesValue
                          isClearable
                        />
                      </div>
                      {servicesForm.touched.unidad_medida_venta_id &&
                        servicesForm.errors.unidad_medida_venta_id && (
                          <div className="fv-plugins-message-container text-danger w-100 mt-2 fs-7">
                            <span role="alert">
                              {servicesForm.errors.unidad_medida_venta_id}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                {!id && (
                  <>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                      <div className="w-100 pe-5">
                        <label className="form-label w-auto">
                          Precio de venta
                        </label>
                        <div className="w-100 d-flex">
                          <NumericFormat
                            className="form-control fs-4"
                            thousandSeparator={true}
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            allowLeadingZeros={false}
                            isAllowed={(values: any) => {
                              const { floatValue } = values;
                              const integerPart = Math.floor(floatValue);
                              return integerPart.toString().length <= 9;
                            }}
                            value={price}
                            onValueChange={(values: any) => {
                              const { floatValue } = values;
                              setPrice(floatValue);
                              servicesForm.setFieldValue(
                                'precio_fijo_venta',
                                floatValue
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                      <div className="w-100 pe-5">
                        <div className="w-100 d-flex">
                          <label className="form-label w-auto">
                            Tipo de impuesto
                          </label>
                        </div>
                        <Select
                          options={TaxeType}
                          value={
                            servicesForm.values.impuesto_id
                              ? TaxeType.find(
                                  (option) =>
                                    option.value ===
                                    servicesForm.values.impuesto_id
                                )
                              : null
                          }
                          // value={selectedOptionTax}
                          styles={styleSelect}
                          placeholder={'Seleccione'}
                          isSearchable={true}
                          noOptionsMessage={() => {
                            return <span>{'No se encontraron registros'}</span>;
                          }}
                          onChange={changeTypeTaxe}
                          isLoading={loadingTT}
                          // isClearable={id > 0 ? true : false}
                          isClearable={true}
                          backspaceRemovesValue
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                      <div className="w-100 pe-5">
                        <label className="form-label w-auto">
                          Precio venta final
                        </label>
                        <div className="w-100 d-flex">
                          <span
                            className="fs-1 fw-bold"
                            style={{ color: '#1B84FF' }}
                          >
                            <CountUp
                              end={totalPrice}
                              duration={1}
                              prefix="$"
                              decimals={2}
                            />
                          </span>
                          <span className="fs-7 fw-semibold opacity-50 mt-3 ms-2">
                            {codeInter && (
                              <>
                                /{' '}
                                <span data-kt-element="period">
                                  {codeInter}
                                </span>
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {id > 0 ? (
          <>
            <div className="card mb-6 mb-xl-9">
              <div
                className="card-header cursor-pointer"
                onClick={() => {
                  setOpenSection(!openSection);
                  // setOpenSectionPurchase(false);
                }}
                aria-controls="collapseProducts"
                aria-expanded={openSection}
              >
                <div className="card-title m-0">
                  <h3 className="fw-bolder m-0">Datos de venta</h3>
                </div>
                <div className="d-flex align-items-center">
                  <div className="text-center me-5">
                    <h6 className="fs-012 text-gray-500">
                      Precio de venta final
                    </h6>
                    <span className="fs-1 fw-bold" style={{ color: '#1B84FF' }}>
                      <CountUp
                        end={totalPriceProduct}
                        duration={1}
                        prefix="$"
                        decimals={2}
                      />{' '}
                    </span>

                    <span className="fs-7 fw-semibold opacity-50">
                      {codeCurrency || codeMeasure ? (
                        <>
                          <span data-kt-element="period">
                            {' '}
                            {codeCurrency ? `${codeCurrency}/` : ''}
                          </span>
                          <span data-kt-element="period">{codeMeasure}</span>
                        </>
                      ) : null}
                    </span>
                  </div>
                  <div
                    className={`align-self-center`}
                    style={{
                      transform: openSection
                        ? 'translateX(10%) rotate(-180deg)'
                        : '',
                      transition: 'transform 0.3s ease',
                    }}
                  >
                    <KTSVG
                      path={'/media/icons/duotune/arrows/arr072.svg'}
                      className={`svg-icon-1`}
                    />
                  </div>
                </div>
              </div>
              <Collapse in={openSection}>
                <div id="collapseProducts">
                  <div className="card-body border-top p-9">
                    <SaleDataSection
                      taxValue={taxPercentaje}
                      // currentSaleTypeProduct={currentSaleTypeProduct}
                      currentUnitMeasureSaleProduct={
                        currentUnitMeasureSaleProduct
                      }
                      currentUnitCurrencyProduct={currentUnitCurrencyProduct}
                      currentProfit={currentProfit}
                      calculatePrice={calculatePrice}
                      currentTypePriceToSale={currentTypePriceToSale}
                      updatePriceProduct={updatePriceProduct}
                      isSupp={supplierTypePurchase}
                      appTax={applyTax}
                      initVal={id > 0 ? initValues : null}
                      formik={servicesForm}
                    />
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="card mb-6 mb-xl-9">
              <div
                className="card-header cursor-pointer"
                onClick={() => {
                  setOpenSectionPurchase(!openSectionPurchase);
                  // setOpenSection(false);
                }}
                aria-controls="collapseServices"
                aria-expanded={openSectionPurchase}
              >
                <div className="card-title m-0">
                  <h3 className="fw-bolder m-0">Datos de adquisición</h3>
                </div>
                <div className="d-flex align-items-center">
                  <div className="text-center me-5">
                    <h6 className="fs-012 text-gray-500">Precio de compra</h6>
                    <span className="fs-1 fw-bold" style={{ color: '#1B84FF' }}>
                      <CountUp
                        end={totalPriceProductToPurchase}
                        duration={1}
                        prefix="$"
                        decimals={2}
                      />
                    </span>
                    <span className="fs-7 fw-semibold opacity-50 ms-1">
                      {codeCurrencyPurchase || codeMeasurePurchase ? (
                        <>
                          <span data-kt-element="period">
                            {codeCurrencyPurchase
                              ? `${codeCurrencyPurchase}/`
                              : ''}
                          </span>

                          <span data-kt-element="period">
                            {codeMeasurePurchase}
                          </span>
                        </>
                      ) : null}
                    </span>
                  </div>

                  <div
                    className={`align-self-center`}
                    style={{
                      transform: openSectionPurchase
                        ? 'translateX(10%) rotate(-180deg)'
                        : '',
                      transition: 'transform 0.3s ease',
                    }}
                  >
                    <KTSVG
                      path={'/media/icons/duotune/arrows/arr072.svg'}
                      className={`svg-icon-1`}
                    />
                  </div>
                </div>
              </div>
              <Collapse in={openSectionPurchase}>
                <div id="collapseServices">
                  <div className="card-body border-top p-9">
                    <PurchaseDataSection
                      codeMeasureSaleProduct={codeMeasure}
                      codeCurrencySaleProduct={codeCurrency}
                      taxValue={taxPercentaje}
                      profitPorcentaje={profit}
                      currentUnitMeasurePurchaseProduct={
                        currentUnitMeasurePurchaseProduct
                      }
                      currentUnitCurrencyPurchaseProduct={
                        currentUnitCurrencyPurchaseProduct
                      }
                      getCalculatePrice={getCalculatePrice}
                      isSupplierType={isSupplierType}
                      updatePricePurchaseProduct={updatePricePurchaseProduct}
                      disableOption={suppliers.length ? false : true}
                      dataSupplier={dataSupplierSelected}
                      priceSale={priceSale}
                      isCheck={isChecked}
                      IsCheckedSupplierSecction={IsCheckedSupplierSecction}
                      initVal={id > 0 ? initValues : null}
                      formik={servicesForm}
                      reloadGridSupplier={reloadGridSupplier}
                      currencyData={servicesForm.values.unidad_moneda_venta_id}
                      measureData={servicesForm.values.unidad_medida_venta_id}
                    />
                  </div>
                </div>
              </Collapse>
            </div>
          </>
        ) : null}

        <div className="card mb-6 mb-xl-9">
          <div className="card-body">
            <div className="row">
              {showAlert ? (
                <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
                  <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                    <KTSVG
                      className="svg-icon-2"
                      path="/media/icons/duotune/general/gen057.svg"
                    />
                  </span>

                  <div className="d-flex flex-column">
                    <h5 className="mb-1">Esta es una alerta</h5>
                    <span>
                      No olvides guardar tus cambios para que no se pierdan.
                    </span>
                  </div>
                </div>
              ) : null}
              <div className="d-flex justify-content-end">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                  onClick={async () => {
                    if (isChecked && showAlert) {
                      const { id: supp } = suppInit;
                      await updateSupplier({
                        id: supp,
                        seleccionado: true,
                        servicio_id: id,
                      });
                    }
                    history.push('/cotizaciones/servicios');
                  }}
                >
                  Cancelar
                </button>
                {id === 0 && (
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    data-kt-users-modal-action="submit"
                    onClick={() => {
                      setIsEdit(true);
                      servicesForm.submitForm();
                    }}
                    disabled={loading}
                  >
                    {!loading && (
                      <span className="indicator-label">Guardar</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: 'block' }}
                      >
                        Espere por favor...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  onClick={() => {
                    servicesForm.submitForm();
                  }}
                  disabled={loading}
                >
                  {!loading && (
                    <span className="indicator-label">
                      {' '}
                      {id ? 'Guardar' : 'Continuar'}
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      {id && !loadingRequest ? (
        <div className="row g-6 g-xl-9" ref={sectionTabs}>
          <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12">
            <div className="card card-flush p-5">
              <div className="row mb-5">
                <div className="col-xxl-8 col-xl-8 col-md-8 col-lg-8">
                  <ul className="nav nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap">
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'NOTE' ? 'text-active-primary active' : ''
                        } `}
                        onClick={() => {
                          setTabs('NOTE');
                        }}
                      >
                        <span className="margin-left-05">Notas</span>
                      </div>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'DOC' ? 'text-active-primary active' : ''
                        } `}
                        onClick={() => {
                          setTabs('DOC');
                        }}
                      >
                        <span className="margin-left-05">Documentos</span>
                      </div>
                    </li>
                    <li className="nav-item cursor-pointer ">
                      <div
                        className={`nav-link me-6 ${
                          tabs === 'PROVEE' ? 'text-active-primary active' : ''
                        } `}
                        onClick={() => {
                          setTabs('PROVEE');
                        }}
                      >
                        <span className="margin-left-05">Proveedores</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                {tabs === 'NOTE' ? (
                  <NoteList
                    data={notes}
                    onOpenModal={openModal}
                    active={false}
                    onOpenDetailModal={openDetailModal}
                  ></NoteList>
                ) : null}
                {tabs === 'DOC' ? (
                  <>
                    <DocumentsList
                      documents={dataDocuments || []}
                      onRefreshData={() => {
                        setReloadDocuments(Math.random() * 100);
                      }}
                      id={initValues.id}
                      isEdit={false}
                    ></DocumentsList>
                  </>
                ) : null}
                {tabs === 'PROVEE' ? (
                  <>
                    <SupplierList
                      serviceId={id}
                      data={suppliers}
                      onUpdateSuppliers={updateSuppliers}
                      supplierData={supplierData}
                      IsCheckedSupplierSecction={IsCheckedSupplierSecction}
                      currencyData={servicesForm.values.unidad_moneda_venta_id}
                      measureData={servicesForm.values.unidad_medida_venta_id}
                      isSuppPrice={supplierTypePurchase}
                      onRefreshData={() => {
                        setReloadSuppliers(Math.random() * 100);
                      }}
                      formik={servicesForm}
                      guardarProveedor={(proveedores) => {
                        setProveedoresForm(proveedores);
                      }}
                    ></SupplierList>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <CreateNoteServiceModal
        show={showNoteModal}
        handleClose={(refresh) => {
          setShowNoteModal(!showNoteModal);
          if (refresh) {
            setReloadGridNotes(Math.random() * 40);
          }
        }}
        id={Number(id)}
      ></CreateNoteServiceModal>
      <CreateNoteDetailModal
        show={showNoteDetailModal}
        handleClose={(refresh) => {
          setShowNoteDetailModal(!showNoteDetailModal);
          onRefreshHistory();
        }}
        data={notes}
        id={id}
      ></CreateNoteDetailModal>
      <CategoryModal
        show={isOpenCategoryModal}
        handleClose={(isNew: boolean) => {
          if (isNew) {
            setReloadCategoryList(Math.random() * 40);
            setSelectCategoryAdding(true);
          } else {
            setSelectedOptionCategory(null);
            setCategoryId(0);
            servicesForm.setFieldValue('categoria_id', 0);
          }
          setIsOpenCategoryModal(!isOpenCategoryModal);
        }}
      ></CategoryModal>

      <SubcategoryModal
        show={isOpenSubcategoryModal}
        handleClose={(isNew: boolean) => {
          if (isNew) {
            setReloadSubcategoryList(Math.random() * 40);
            setSelectSubcategoryAdding(true);
          } else {
            setSelectedOptionSubcategory(null);
            servicesForm.setFieldValue('sub_categoria_id', 0);
          }
          setIsOpenSubcategoryModal(!isOpenSubcategoryModal);
        }}
        category={selectedOptionCategory}
      ></SubcategoryModal>

      <ServiceTypeModal
        show={isOpenServiceTypeModal}
        handleClose={(isNew: boolean) => {
          if (isNew) {
            setReloadServiceList(Math.random() * 40);
            setSelectServiceTypeAdding(true);
          } else {
            setSelectedOptionServiceType(null);
            servicesForm.setFieldValue('tipo_id', 0);
          }
          setIsOpenServiceTypeModal(!isOpenServiceTypeModal);
        }}
      ></ServiceTypeModal>

      <BussinesUnitModal
        show={isOpenBussinesUnitModal}
        handleClose={(isNew: boolean) => {
          if (isNew) {
            setReloadBussinesUnit(Math.random() * 50);
            setSelectBussinesUnitAdding(true);
          } else {
            setSelectedOptionBussinesUnit(null);
            servicesForm.setFieldValue('unidad_negocio_id', 0);
          }
          setIsOpenBussinesUnitModal(!isOpenBussinesUnitModal);
        }}
      ></BussinesUnitModal>
    </>
  );
};
export { ServiceForm };
